// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--cOzNT";
export var hero__title = "hero-module--hero__title--OwPFD";
export var hero__desc = "hero-module--hero__desc--UYEOM";
export var hero__media = "hero-module--hero__media---sQqN";
export var hero__gradient = "hero-module--hero__gradient--cu2R1";
export var hero__gradient_laptop = "hero-module--hero__gradient_laptop--XMPTc";
export var hero__gradient_tablet = "hero-module--hero__gradient_tablet--OJcOf";
export var hero__gradient_mobile = "hero-module--hero__gradient_mobile--TceZY";
export var hero_textLeft = "hero-module--hero_text-left--P6RMH";
export var hero__button = "hero-module--hero__button--RNyph";
export var hero__gradient_desktop = "hero-module--hero__gradient_desktop--3yIzU";