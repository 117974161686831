import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../../components/Layout/layout";
import Seo from "../../components/SEO/SEO";
import SolutionHero from "../../components/Solution/Hero";
import ProductContent from "../../components/Product/Content";
import Blog from "../../components/Home/Blog";
import FreeDemoForm from "../../components/Form/FreeDemo";
import * as pageStyles from "./solution-page.module.css";

const ContentGradient = (
    <div className={pageStyles.solutionPage__gradientWrapper}>
        <StaticImage
            src="../../assets/images/solutions/content/mobile.png"
            alt=""
            placeholder="none"
            layout="fixed"
            className={`${pageStyles.solutionPage__gradient} ${pageStyles.solutionPage__gradient_mobile}`}
        />
        <StaticImage
            src="../../assets/images/solutions/content/tablet.png"
            alt=""
            placeholder="none"
            layout="fixed"
            className={`${pageStyles.solutionPage__gradient} ${pageStyles.solutionPage__gradient_tablet}`}
        />
        <StaticImage
            src="../../assets/images/solutions/content/laptop.png"
            alt=""
            placeholder="none"
            layout="fixed"
            className={`${pageStyles.solutionPage__gradient} ${pageStyles.solutionPage__gradient_laptop}`}
        />
        <StaticImage
            src="../../assets/images/solutions/content/desktop.png"
            alt=""
            placeholder="none"
            layout="fixed"
            className={`${pageStyles.solutionPage__gradient} ${pageStyles.solutionPage__gradient_desktop}`}
        />
    </div>
);

const SolutionPage = ({data: {solution, articles}}) => {
    const {seo} = solution;

    return (
        <Layout className={pageStyles.solutionPage}>
            <Seo seo={seo} />
            <SolutionHero
                title={solution.title}
                description={solution.description}
                media={solution.heroMedia}
                variant={solution.heroVariant}
            />
            <ProductContent content={solution.content} gradient={ContentGradient} />
            <Blog articles={articles} />
            <FreeDemoForm />
        </Layout>
    );
};

export const pageQuery = graphql`
    query ($id: String) {
        solution: strapiSolutions(id: {eq: $id}) {
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            title
            description
            heroVariant
            heroMedia {
                youtubeVideoURL
                content {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                }
            }
            content {
                id
                title
                description
                type
                appStoreURL
                googlePlayURL
                item {
                    id
                    title
                    description
                    media {
                        id
                        youtubeVideoURL
                        content {
                            mime
                            width
                            height
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(placeholder: NONE)
                                }
                            }
                        }
                    }
                }
                media {
                    id
                    youtubeVideoURL
                    content {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                }
            }
        }
        articles: allStrapiArticles(limit: 3, sort: {fields: published_at, order: DESC}, filter: {}) {
            edges {
                node {
                    title
                    description
                    readingTime
                    published_at
                    slug
                    tags: articleTags {
                        title
                        slug
                    }
                }
            }
        }
    }
`;

export default SolutionPage;
