import classNames from "classnames";
import PropTypes from "prop-types";
import * as React from "react";
import Arrow from "../../assets/svg/arrow.svg";
import * as cardStyles from "./product-content-card.module.css";

const ContentCard = ({title, description, toggle, isOpen, className = ""}) => {
    return (
        <article
            className={classNames(className, cardStyles.productContentCard, {
                [cardStyles.productContentCard_active]: isOpen,
            })}
        >
            <div>
                <h4 className={cardStyles.productContentCard__title}>
                    {title} <Arrow />
                </h4>
                <p className={cardStyles.productContentCard__desc}>{description || ""}</p>
            </div>
            <button
                type="button"
                className={cardStyles.productContentCard__button}
                onClick={toggle}
                aria-label={isOpen ? "Закрыть описание" : "Открыть описание"}
            />
        </article>
    );
};

ContentCard.defaultProps = {
    description: "",
    isOpen: false,
    className: "",
};

ContentCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    className: PropTypes.string,
};

export default ContentCard;
