import classNames from "classnames/bind";
import PropTypes from "prop-types";
import * as React from "react";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import Media from "../Common/Media";
import Link from "../UI/Link";
import * as cardStyles from "./article-card.module.css";

const cx = classNames.bind(cardStyles);

const ArticleCard = ({title, description, date, readingTime, url, tag, featuredImage}) => {
    return (
        <article className={cx({articleCard: true})}>
            <div className={cx({articleCard__mainContent: true})}>
                <Link
                    className={cx({articleCard__link: true})}
                    to={url}
                    withArrow={false}
                    aria-label={`Перейти к статье "${title}"`}
                />{" "}
                {featuredImage && (
                    <div className={cx({articleCard__image: true})}>
                        <Media media={{content: featuredImage, youtubeVideoURL: ""}} />
                    </div>
                )}
                <h4 className={cx({articleCard__title: true})}>{title}</h4>
                {description.length > 0 && <p className={cx({articleCard__desc: true})}>{description}</p>}{" "}
            </div>
            <div>
                {tag && (
                    <Link
                        className={cx({articleCard__tag: true})}
                        to={tag.url}
                        withArrow={false}
                        small
                        aria-label={`Перейти к списку статей по тэгу "${tag.title}"`}
                    >
                        {tag.title}
                    </Link>
                )}
                <p className={cx({articleCard__note: true})}>
                    {date && <time dateTime={date.toISOString()}>{format(date, "d MMMM yyyy", {locale: ru})}, </time>}
                    <span>{readingTime || 0} мин читать</span>
                </p>
            </div>
        </article>
    );
};

ArticleCard.defaultProps = {
    description: "",
    tag: null,
    featuredImage: null,
};

ArticleCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    date: PropTypes.instanceOf(Date).isRequired,
    readingTime: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    tag: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }),
    featuredImage: PropTypes.shape({
        mime: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        localFile: PropTypes.shape({
            publicURL: PropTypes.string,
            childImageSharp: PropTypes.shape({
                gatsbyImageData: PropTypes.shape({}),
            }),
        }),
    }),
};

export default ArticleCard;
