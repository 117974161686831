import PropTypes from "prop-types";
import * as React from "react";
import Section from "../Layout/Section";
import SectionTitle from "../Layout/Section/Title";
import ArticleCard from "../Article/Card";
import Link from "../UI/Link";
import * as blogStyles from "./blog.module.css";

const Blog = ({articles: {edges: articles}}) => {
    return (
        <Section>
            <div className={blogStyles.blog__header}>
                <SectionTitle>Блог</SectionTitle>
                <Link to="/blog" withArrow>
                    Все статьи
                </Link>
            </div>
            <div className={blogStyles.blog__articles}>
                {articles.map(({node: article}, idx) => {
                    const articleData = {
                        title: article.title,
                        description: article.description,
                        date: new Date(article.published_at),
                        readingTime: article.readingTime,
                        url: `/blog/${article.slug}`,
                        tag: article.tags[0] && {
                            title: article.tags[0].title,
                            url: `/blog?tags=${article.tags[0].slug}`,
                        },
                    };

                    return <ArticleCard {...articleData} key={idx} />;
                })}
            </div>
        </Section>
    );
};

Blog.propTypes = {
    articles: PropTypes.exact({
        edges: PropTypes.arrayOf(
            PropTypes.exact({
                node: PropTypes.exact({
                    title: PropTypes.string.isRequired,
                    description: PropTypes.string.isRequired,
                    readingTime: PropTypes.number.isRequired,
                    published_at: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                    tags: PropTypes.arrayOf(
                        PropTypes.exact({
                            title: PropTypes.string.isRequired,
                            slug: PropTypes.string.isRequired,
                        }).isRequired,
                    ),
                }).isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

export default Blog;
