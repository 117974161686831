import classNames from "classnames";
import {StaticImage} from "gatsby-plugin-image";
import {useCallback} from "react";
import * as React from "react";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import Section from "../Layout/Section";
import Button from "../UI/Button";
import * as heroStyles from "./hero.module.css";

const SolutionHero = ({title, description, media, variant = "text_above"}) => {
    const scrollToForm = useCallback(() => {
        const form = document.querySelector("#free-demo-form");
        if (!form) {
            return;
        }

        form.scrollIntoView({behavior: "smooth"});
    }, []);

    return (
        <Section>
            <div className={classNames(heroStyles.hero, {[heroStyles.hero_textLeft]: variant === "text_left"})}>
                <h1 className={heroStyles.hero__title}>
                    <TemplateString template={title} />
                </h1>
                <p className={heroStyles.hero__desc}>{description}</p>
                <Button variant="primary" className={heroStyles.hero__button} onClick={scrollToForm}>
                    Записаться на демо
                </Button>
                {media && (
                    <div className={heroStyles.hero__media}>
                        <Media media={media} big={variant !== "text_left"} />
                    </div>
                )}
                <StaticImage
                    src="../../assets/images/solutions/hero/mobile.png"
                    alt=""
                    placeholder="none"
                    layout="fixed"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_mobile}`}
                />
                <StaticImage
                    src="../../assets/images/solutions/hero/tablet.png"
                    alt=""
                    placeholder="none"
                    layout="fixed"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_tablet}`}
                />
                <StaticImage
                    src="../../assets/images/solutions/hero/laptop.png"
                    alt=""
                    placeholder="none"
                    layout="fixed"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_laptop}`}
                />
                <StaticImage
                    src="../../assets/images/solutions/hero/desktop.png"
                    alt=""
                    placeholder="none"
                    layout="fixed"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_desktop}`}
                />
            </div>
        </Section>
    );
};

export default SolutionHero;
