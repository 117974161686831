import classNames from "classnames";
import {useCallback, useState} from "react";
import * as React from "react";
import AppStore from "../../assets/svg/stores/appStore.svg";
import GooglePlay from "../../assets/svg/stores/googlePlay.svg";
import ContentBlock from "../Layout/ContentBlock";
import ContentCard from "./ContentCard";
import Media from "../Common/Media";
import * as contentStyles from "./product-content.module.css";

const ContentMedia = ({media, gradient}) => {
    return (
        <div className={contentStyles.productContent__media}>
            <Media media={media} /> {gradient}
        </div>
    );
};

const InfoBlock = ({title, description, reversed, media, gradient}) => {
    return (
        <ContentBlock
            alignTop
            alignLeft
            title={title}
            desc={description}
            reversed={reversed}
            rightContent={<ContentMedia media={media} gradient={gradient} />}
            className={classNames({
                [contentStyles.productContent]: true,
                [contentStyles.productContent_reversed]: reversed,
            })}
        />
    );
};

const FeatureBlock = ({title, description, reversed, item, type, id, gradient}) => {
    const [openedItemIndex, setOpenedItemIndex] = useState(0);

    const toggleItem = useCallback(
        (itemIndex) => {
            setOpenedItemIndex(itemIndex);
        },
        [setOpenedItemIndex],
    );

    const leftContent = (
        <div>
            {item.map((entry, idx) => {
                const cardData = {
                    title: entry.title,
                    description: entry.description,
                    isOpen: idx === openedItemIndex,
                    toggle: () => toggleItem(idx),
                };

                return (
                    <ContentCard
                        {...cardData}
                        key={`${type}.${id}.${entry.id}`}
                        className={contentStyles.productContent__featureItem}
                    />
                );
            })}
        </div>
    );

    return (
        <ContentBlock
            alignTop
            alignLeft
            title={title}
            desc={description}
            reversed={reversed}
            leftContent={leftContent}
            rightContent={
                item.length > 0 && (
                    <ContentMedia key={openedItemIndex} media={item[openedItemIndex].media} gradient={gradient} />
                )
            }
            className={classNames({
                [contentStyles.productContent]: true,
                [contentStyles.productContent_reversed]: reversed,
            })}
        />
    );
};

const MobileAppsBlock = ({title, description, reversed, appStoreURL, googlePlayURL, media, gradient}) => {
    const leftContent = (
        <div className={contentStyles.productContent__mobileApps}>
            {appStoreURL && (
                <a
                    href={appStoreURL}
                    target="_blank"
                    rel="noreferrer"
                    className={contentStyles.productContent__mobileAppsLink}
                >
                    <AppStore />
                </a>
            )}{" "}
            {googlePlayURL && (
                <a
                    href={googlePlayURL}
                    target="_blank"
                    rel="noreferrer"
                    className={contentStyles.productContent__mobileAppsLink}
                >
                    <GooglePlay />
                </a>
            )}
        </div>
    );

    return (
        <ContentBlock
            alignTop
            alignLeft
            title={title}
            desc={description}
            reversed={reversed}
            leftContent={leftContent}
            rightContent={<ContentMedia media={media} gradient={gradient} />}
            className={classNames({
                [contentStyles.productContent]: true,
                [contentStyles.productContent_reversed]: reversed,
            })}
        />
    );
};

const typeToComponent = {
    info: InfoBlock,
    feature: FeatureBlock,
    mobileApps: MobileAppsBlock,
};

const ProductContent = ({content, gradient}) => {
    return (
        <>
            {content.map((block, idx) => {
                const isReversed = idx % 2 !== 0;

                const Component = typeToComponent[block.type];
                return <Component {...block} reversed={isReversed} gradient={gradient} key={block.id} />;
            })}
        </>
    );
};

export default ProductContent;
